/* DropdownMenuButtons.css */

.dropdown-menu-buttons {
    display: flex;
    align-items: center;
    padding: 10px;
}

.menu-button {
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    cursor: pointer;
    outline: none;
    font-size: 11px;
    margin-top: -15px;
    color: black;
}

.menu-button.active {
    background-color: floralwhite;
}

.menu-button:hover {
    background-color: whitesmoke;
    color: orange;
    /* Add this line to change font color to white on hover */
}

.menu-button::after {
    content: "▼";
    /* You can use any arrow-down symbol or icon here */
    margin-left: 5px;
}

.menu-button:hover::after {
    content: "▲";
    /* You can use any arrow-down symbol or icon here */
    margin-left: 5px;
}

/* Styles for the brand dropdown */
.brand-dropdown {
    position: absolute;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 150px;
    margin-top: 270px;
    margin-left: 10px;
    /* Adjust the width as needed */
}


.brand-dropdown button {
    padding: 10px;
    text-align: left;
    border: none;
    background: none;
    cursor: pointer;
    transition: background-color 0.3s;
    color: black;
}

.brand-dropdown button:hover {
    background-color: #f0f0f0;
}

/* Add styles for the active (selected) brand */
.brand-dropdown button.active {
    background-color: orange;
    color: #fff;
}

.category-dropdown {
    position: absolute;
    left: 88px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 150px;
    margin-top: 320px;
    margin-left: -18px;
    max-height: 300px;
    /* Example maximum height */
    overflow-y: auto;
}


.category-dropdown button {
    padding: 10px;
    text-align: left;
    border: none;
    background: none;
    cursor: pointer;
    transition: background-color 0.3s;
    color: black;
}

.category-dropdown button:hover {
    background-color: #f0f0f0;
}

/* Add styles for the active (selected) brand */
.category-dropdown button.active {
    background-color: orange;
    color: #fff;
}

.ranking-dropdown {
    position: absolute;
    left: 174px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 150px;
    margin-top: 135px;
    margin-left: -43px;
}

.ranking-dropdown button {
    padding: 10px;
    text-align: left;
    border: none;
    background: none;
    cursor: pointer;
    transition: background-color 0.3s;
    color: black;
}

.ranking-dropdown button:hover {
    background-color: #f0f0f0;
}

/* Add styles for the active (selected) brand */
.ranking-dropdown button.active {
    background-color: orange;
    color: #fff;
}