.admin-item-search-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding-bottom: 100px;
}

.admin-item-search-page input {
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* .admin-item-search-page button {
    padding: 10px 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
} */

.admin-item-search-page button:hover {
    background-color: #45a049;
}

.admin-item-search-page table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.admin-item-search-page th,
.admin-item-search-page td {
    text-align: left;
    padding: 8px;
    border: 1px solid #ddd;
}

.admin-item-search-page th {
    background-color: #f2f2f2;
}

.admin-item-search-page tr:nth-child(even) {
    background-color: #f9f9f9;
}

.admin-item-search-page tr:hover {
    background-color: #f1f1f1;
}