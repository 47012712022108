.search-page {
    font-family: Arial, sans-serif;
    margin-top: 80px;
    padding: 0;
}

.brand-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

.brand-link {
    text-decoration: none;
}

@media (max-width: 768px) {
    .brand-button {
        padding: 0.75rem 1.5rem;
        font-size: 0.9rem;
        height: 100%;
    }
}