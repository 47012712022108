.bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: black;
    color: white;
    display: flex;
    justify-content: space-around;
    padding: 10px;
    z-index: 120;
}

.nav-item {
    display: flex;
    align-items: center;
    font-size: 24px;
}

.bottom-nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: white;
    opacity: 0.7;
    cursor: pointer;
    margin-right: 20px;
}

.bottom-nav-item.active {
    /* Update the gradient colors */
    color: orange;
    /* Set the text color to white for better visibility */
}

.bottom-nav-item span {
    margin-top: 5px;
}