/* AdminOrderPage.css */

.admin-order-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding-bottom: 100px;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.order-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.order-card {
    flex: 1;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.order-card.selected {
    background-color: #f0f0f0;
}

.order-info {
    margin-bottom: 10px;
    padding: 5px;
}

.order-info strong {
    font-weight: bold;
    margin-right: 5px;
}

.order-actions {
    margin-top: 20px;
}

label {
    font-weight: bold;
}

admin-page-input[type="text"] {
    width: 100%;
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

admin-page-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

/* button:hover {
    background-color: #0056b3;
} */

/* Add more styles as needed */

/* Style for the "Update Status" section */
.admin-change-status {
    margin-bottom: 10px;
    text-align: left;
}

.admin-change-status select {
    padding: 5px;
    margin-right: 10px;
}

.admin-change-status button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.admin-change-status button:hover {
    background-color: #0056b3;
}

/* Style for the "Update Logistics" section */
.admin-add-logistics-id {
    text-align: left;
}

.admin-add-logistics-id input {
    /* width: 200px; */
    padding: 5px;
    margin-right: 10px;
}

.admin-add-logistics-id button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.admin-add-logistics-id button:hover {
    background-color: #0056b3;
}