.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: black;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
}

.navbar-left {
    display: flex;
    align-items: center;
    font-size: 24px;
}

.navbar-center {
    flex-grow: 1;
    text-align: center;
}

.navbar-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 20px;
}

.navbar-right>svg {
    margin-left: 10px;
}



.navbar-title>svg {
    margin-right: 10px;
}

@media (min-width: 768px) {
    .navbar-right {
        margin-right: 30px;
    }
}

.flag-icon {
    margin-top: 2px;
    margin-left: 4px;
    width: 24px;
    height: 22px;
    border-radius: 50%;
}


.dropdown {
    position: relative;
    display: inline-block;

}

.dropdown-toggle {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 200px;
    background-color: black;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 10px;
    list-style: none;
}

.dropdown-menu li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dropdown-menu li:hover {
    background-color: orange;
}

.dropdown-menu li:empty {
    display: none;
}

.submenu {
    position: absolute;
    top: 0;
    left: 100%;
    width: 200px;
    background-color: black;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 10px;
    list-style: none;
}

.submenu li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submenu li:hover {
    background-color: orange;
}

.more-icon {
    position: absolute;
    right: 10px;
}

.badge {
    position: absolute;
    top: 5px;
    right: 20px;
    background-color: orangered;
    color: white;
    border-radius: 50%;
    width: 20px;
    /* Example size */
    height: 20px;
    font-size: 0.8rem;
    line-height: 20px;
    /* Same as height to center text */
    text-align: center;
}

.cart-container {
    padding-left: 2px;
}