/* FloatingChatChannel.css */
.floating-chat-channel {
    position: fixed;
    bottom: 10%;
    right: 20px;
    z-index: 1000;
}

.whatsapp-button {
    background-color: rgb(48, 190, 45);
    /* WhatsApp green color */
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.whatsapp-button img {
    width: 100%;
    height: 100%;
}