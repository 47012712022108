.account-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.account-info-container input {
    margin-bottom: 10px;
    padding: 10px;
    width: 80%;
}

.account-info-container span {
    margin-bottom: 10px;
    padding: 0px;
    width: 85%;
}

.save-changes-button {
    margin-top: 10px;
    padding: 10px;
    color: white;
    background: black;
    border-radius: 0.6rem;
    width: 80%;
    cursor: pointer;
}

.add-to-cart-button {
    margin-top: 10px;
    padding: 10px;
    color: white;
    background: black;
    border-radius: 0.6rem;
    width: 100%;
    cursor: pointer;
}

.back-button {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
}

.backButton {
    background-color: orangered;
    border: none;
    color: white;
    padding: 7px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    transition-duration: 0.4s;
    border-radius: 5px;
}

.input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    width: 85%;
}

.input-container input {
    flex-grow: 1;
    margin-right: 10px;

}