/* DropdownComponent.css */
.dropdown-container {
    /* position: relative; */
    display: inline-block;
    margin-top: 10px;
    width: 80%;
    /* Adjust the width as needed */
}

.dropdown-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    color: #333;
    padding: 12px 20px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dropdown-button:hover {
    background-color: #ddd;
}

.dropdown-content {
    position: absolute;
    left: 0;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 12px;
    width: 100%;
    max-height: 160px;
    /* Adjust the max height as needed */
    overflow-y: auto;
    display: none;
}

.dropdown-container.show .dropdown-content {
    display: block;
}

.item {
    margin: 8px 0;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.item:hover {
    background-color: #f0f0f0;
}

.item-details {
    margin-top: 16px;
}

.item-details p {
    margin: 4px 0;
}

/* Additional styling for the form */
.form {
    display: none;
    margin-top: 16px;
}

.dropdown-container.show .form {
    display: block;
}

.form input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 8px;
}

.form button {
    background-color: #333;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 16px;
}

.form button:hover {
    background-color: #555;
}

.item.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
    /* This ensures that the item is not clickable */
}