.create-spu-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding-bottom: 300px;
}

.create-spu-page h2 {
    text-align: center;
    margin-bottom: 20px;
}

form {
    display: flex;
    width: 90%;
    flex-direction: column;
}

.create-spu-page div {
    margin-bottom: 10px;
}

/* .create-spu-page label,
input,
textarea,
button {
    width: 100%;
    margin-bottom: 10px;
}
*/

.item-size-input {
    width: 100%;
    margin-bottom: 10px;
}

.create-spu-page input,
textarea,
button {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.add-item-button {
    background-color: #28a745;
    color: #fff;
    cursor: pointer;
    border: none;
    margin-bottom: 20px;
}

.remove-button {
    width: 100px;
    background-color: red;
    color: #fff;
    cursor: pointer;
    border: none;
}

.create-spu-button {
    background-color: blue;
    color: #fff;
    cursor: pointer;
    border: none;
}

/* 
button:hover {
    background-color: #218838;
}

hr {
    margin: 10px 0;
    border: 0;
    border-top: 1px solid #ccc;
} */

.image-preview {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}

.image-container {
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
}

.image-container img {
    max-width: 150px;
    max-height: 150px;
    border-radius: 5px;
}