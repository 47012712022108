.hero-carousel {
    position: relative;
    width: 100%;
    /* Adjust the height as needed */
    overflow: hidden;
    padding-bottom: 20px;
}

.carousel-image {
    /* height: 250px; */
}

.carousel-slides {
    display: flex;
    transition: transform 0.3s ease;
}

.slide {
    flex: 0 0 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.carousel-controls {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}

.carousel-control {
    display: inline-block;
    font-size: 24px;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    color: #fff;
    padding: 0 15px;
    transition: opacity 0.3s ease;
}

.carousel-control:hover {
    opacity: 0.7;
}

.prev {
    margin-right: 15px;
}

.next {
    margin-left: 15px;
}