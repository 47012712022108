.button-slider {
    width: 100%;
    overflow-x: auto;
    /* Enable horizontal scrolling on small screens */
    white-space: nowrap;
    padding: 10px 0;
    /* Keep buttons in a single row */
}

.button-slider-container {
    display: inline-flex;
    /* Use inline-flex for single row */
    gap: 10px;
}

.slider-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease-in-out;
}

.slider-button.active {
    background-color: #007bff;
    color: #fff;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
    .button-slider {
        overflow-x: auto;
        /* Enable horizontal scrolling on small screens */
        white-space: nowrap;
        /* Keep buttons in a single row */
        padding: 10px 0;
        /* Add some padding to the container for better visibility */
    }

    .button-slider-container {
        flex-wrap: nowrap;
        /* Prevent wrapping on small screens */
    }
}