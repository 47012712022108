.cart-page-container {
    border: 1px solid #eee;
    padding: 20px;
    padding-bottom: 200px;
    /* adjust this based on your layout */
}

.cart-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.cart-item {
    border-bottom: 1px solid #eee;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.product-image-cart {
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 4px;
}

.product-details-cart {
    flex: 1;
}

.cart-total {
    text-align: right;
    margin-top: 20px;
    font-weight: bold;
}

.cart-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.checkout-button {
    padding: 10px 20px;
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.checkout-button:hover {
    background-color: #0056b3;
}

.product-name-cart {
    padding-right: 5px;
}

.product-size-cart {
    padding: 3px 8px;
    margin-right: 5px;
    margin-bottom: 5px;
    background-color: #f2f2f2;
    border-radius: 5px;
    font-size: 12px;

}

.remove-button {
    background-color: #f44336;
    /* red */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 15px;
    /* adds space between the product details and the button */
}

.remove-button:hover {
    background-color: #d32f2f;
    /* a darker red for hover effect */
}

.remove-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(243, 67, 54, 0.5);
    /* a light red focus ring */
}

.remove-button .fa-trash {
    margin-right: 5px;
    /* adds a little space between the trash icon and the text */
}

.product-detail-cart {
    display: flex;
    flex-direction: column;
    /* stack children vertically */
    justify-content: center;
    /* center the items vertically within the container */
    gap: 8px;
    /* provides consistent space between the children */
    padding: 10px;
    /* a little padding to make it visually appealing */
}

.product-name-cart {
    font-weight: 500;
    /* semi-bold */
    font-size: 1.2rem;
    /* a bit larger font for the product name */
    margin-bottom: 5px;
    /* a little space between the name and the rest of the details */
}

.product-size-cart,
.product-price-cart {
    font-size: 1rem;
    /* default size */
    color: #555;
    /* slightly muted color for details as compared to the name */
}

.product-details-button-wrapper {
    flex: 1;
    /* Takes available space */
    display: flex;
    align-items: center;
    /* Vertically center children */
    justify-content: space-between;
    /* Spread product details and the button */
}

/* Style for the "Select Address" button */
.select-button {
    background-color: #007bff;
    /* Blue color, you can change this */
    color: #fff;
    /* White text color */
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
    /* Adjust the margin as needed */
}

/* Style for the "Close" button */

.close-button {
    background-color: #dc3545;
    /* Red color, you can change this */
    color: #fff;
    /* White text color */
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 13px;
    right: 15px;
}

/* Add hover effects if desired */
.select-button:hover,
.close-button:hover {
    opacity: 0.8;
    /* Reduce opacity on hover for a button effect */
}

.address-modal-container {
    padding-bottom: 100px;
}

.selected-address-card {
    background-color: #f4433690;
    /* Change to your desired background color */
    /* Add any other styling you want for the selected card */
}

.address-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin: 10px 0;
    width: 90%;
    cursor: pointer;
}

.promo-code-row {
    display: flex;
    /* Use flexbox to align items in a row */
    align-items: center;
    /* Vertically center items */
    gap: 10px;
    /* Add some space between label and input */
}

.promo-label {
    flex: 1;
    /* Make the label expand to fill available space */
}

.cart-page-input[type="text"] {
    padding: 5px;
    margin-top: 7px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}


/* Adjust button styles to fit your design */