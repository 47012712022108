.order-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding-bottom: 100px;
}

.header {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.backButton {
    align-self: flex-start;
}

.order-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.order-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin: 10px 0;
    width: 90%;
    cursor: pointer;
}

.order-card:hover {
    background-color: #f0f0f0;
}

.order-info {
    width: 100%;
}

.order-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 2px;
}

.itemsContainer {}

.button-container1 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px;
}

.address-button {
    background-color: white;
    color: black;
    border: 1px solid black;
    padding: 7px 15px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 5px;
    transition-duration: 0.4s;
    text-decoration: none;
    text-align: center;
    display: inline-block;
}

input {
    margin-bottom: 10px;
}

.form-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

@media (min-width: 768px) {
    .form-row {
        flex-direction: row;
        justify-content: space-between;
    }

    .form-row input {
        width: 70%;
    }
}

.custom-button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    margin-left: 2px;
}

/* Styles for Save button */
.save-button {
    background-color: #007bff;
    /* Replace with your desired color */
    color: #fff;
}

.save-button:hover {
    background-color: #0056b3;
    /* Replace with your desired hover color */
}

/* Styles for Cancel button */
.cancel-button {
    background-color: #dc3545;
    /* Replace with your desired color */
    color: #fff;
}

.cancel-button:hover {
    background-color: #b3001f;
    /* Replace with your desired hover color */
}

.order-row-first {
    width: 120px;
    padding-right: 20px;

}

.button-group {
    display: flex;
    gap: 10px;
    /* Adjust the gap between the buttons as needed */
    justify-content: center;
    /* Centers the buttons in the row */
}