/* Add styling for the navigation button to toggle filters */
.toggle-filters-button {
    padding: 10px 10px;
    background-color: orangered;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 80%;
}

.button-container {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

/* Style for the button when hovered */
.toggle-filters-button:hover {
    background-color: orangered;
}

/* Style for the filters-container */
.filters-container {
    /* Add your existing styles for the filters-container here */
    /* Example:
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    background-color: #f9f9f9;
    */
}

/* Hide the filters-container by default */
.filters-container.hidden {
    display: none;
}

/* Show the filters-container when visible class is applied */
.filters-container.visible {
    display: block;
}

.homepage-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Styles for the HeroCarousel container */
.hero-carousel-container {
    position: relative;
    width: 100%;
    /* Add some spacing between the carousel and other content */
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    /* semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* high z-index to ensure it's on top */
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    /* or set a fixed width like 500px */
    max-width: 500px;
    /* adjust as per your design */
}

.marvoo-slogan-1 {
    padding-left: 10px;

    padding-right: 10px;
    text-align: center;
    /* Center-align the text */
    /* Add some top margin for spacing */
    font-size: 14px;
    /* Adjust the font size as needed */
    color: #333;

    /* Change the text color as needed */
}

.marvoo-slogan-2 {
    padding-left: 10px;
    margin-top: -10px;
    padding-right: 10px;
    text-align: center;
    /* Center-align the text */
    /* Add some top margin for spacing */
    font-size: 14px;
    /* Adjust the font size as needed */
    color: #333;
    /* Change the text color as needed */
}