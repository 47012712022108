.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 90px;
}

.signup-container input {
    margin-bottom: 10px;
    padding: 10px;
    width: 70%;
}

.signup-button,
.google-signup-button,
.facebook-signup-button {
    margin-top: 10px;
    padding: 10px;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signup-button {
    background: black;
    border-radius: 0.6rem;
    width: 80%;
}

.google-signup-button {
    background: white;
    color: black;
    border-radius: 0.6rem;
    width: 80%;
    border-color: black;
}

.facebook-signup-button {
    background: white;
    color: black;
    border-radius: 0.6rem;
    width: 80%;
    border-color: black;
}

.button-logo {
    height: 20px;
    width: 20px;
    margin-right: 5px;
}

.term-text-containter {
    padding: 10px;
    width: 80%;
}

.or-text-containter {
    text-align: center;
}

.access-toggle {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.toggle-option {
    background-color: orangered;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    transition-duration: 0.4s;
    border-radius: 5px;
}

.toggle-option:hover,
.toggle-option.active {
    background-color: white;
    color: black;
    border: 2px solid orangered;
}

.toggle-message {
    padding: 2%;
}

.toggle-message-action {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.toggle-message-action:hover {
    color: darkblue;
}