.hint-tips {
    position: relative;
}

.button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.message-box {
    position: absolute;
    top: 100%;
    width: 150px;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: none;
}

.hint-tips:hover .message-box {
    display: block;
}

.hint-button {
    background-color: #007bff;
    color: #fff;
    border-radius: 100px;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 10px;
    /* Adjust the size as needed */
    line-height: 1;
}