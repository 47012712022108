/* Assuming the class name for the brand button is .brand-button */
.brand-button {
    display: grid;
    place-items: center;
    /* Center the content both horizontally and vertically */
    width: 120px;
    /* Set a specific width for the button */
    padding: 8px;
    font-size: 16px;
    color: grey;
    /* Black text color */
    background-color: #fff;
    /* White background color */
    border: 1px solid #ccc;
    /* Grey border */
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
    /* Allow the text to wrap into two rows */
    word-wrap: break-word;
    text-align: center;
}

/* Add hover styles to change button appearance on hover */
.brand-button:hover {
    background-color: #ccc;
    /* Grey background color on hover */
    color: #fff;
    /* White text color on hover */
    border-color: #999;
    /* Darker grey border on hover */
}

/* Add active styles to change button appearance when clicked */
.brand-button:active {
    background-color: #999;
    /* Darker grey background color when clicked */
    color: #fff;
    /* White text color when clicked */
    border-color: #777;
    /* Even darker grey border when clicked */
}