.product-detail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;
}

.product-name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.product-image {
    width: 100%;
    max-width: 400px;
    height: auto;
    margin-bottom: 20px;
    /* height: 200px; */
}

.product-description {
    font-size: 16px;
    margin-bottom: 20px;
}

.product-price {
    font-size: 20px;
    margin-bottom: 10px;
}

.product-composition {
    font-size: 16px;
}

@media screen and (max-width: 768px) {
    .product-detail-container {
        padding: 10px;
    }

    .product-name {
        font-size: 20px;
    }

    .product-image {
        max-width: 300px;
    }

    .product-description {
        font-size: 14px;
    }

    .product-price {
        font-size: 18px;
    }

    .product-composition {
        font-size: 14px;
    }
}

.back-button {
    background-color: #e91e63;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    /* Add any back button styles here */
}

.back-button:hover {
    background-color: #d81b60;
}

.back-button:focus {
    outline: none;
}

.carousel .slider-wrapper {
    border: 1px solid #ddd;
    border-radius: 4px;
    max-width: 100%;
    /* Set a maximum width for the carousel */
    overflow: hidden;
    /* Hide any overflowing content */
}

/* Adjust the size of the carousel images */
.carousel .slider-wrapper .slider .slide img {
    width: 50%;
    /* Make the image width 100% of the container */
    height: auto;
    /* Automatically adjust the height to maintain aspect ratio */
    border-radius: 4px;
}

.productpage-heart-icon {
    color: gray
}

.productpage-heart-icon.red {
    color: red;
}

.additional-images {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    overflow-x: auto;
    /* Add horizontal scroll */
    padding-bottom: 10px;
    /* Add some space for the horizontal scrollbar */
}

.additional-image-wrapper {
    margin-right: 10px;
}

.additional-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.product-container {
    justify-content: space-between;
    width: 100%;
    padding-bottom: 200px;
}

.small-image {
    width: 30px;
    height: 30px;
    margin-right: 5px;
}

.centered-content {
    display: flex;
    align-items: center;
}

.price-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ui-container {
    display: flex;
    flex-direction: column;
}

.divider {
    border: none;
    border-top: 1px solid #ccc;
    margin: 10px 0;
}