.price-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.price-popup.show {
    display: flex;
}

.price-popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    max-width: 80%;
    max-height: 50%;
    overflow-y: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: absolute;
    /* Added for positioning the close button */
}