/* ... previous styles ... */

.product-filter-container {
    position: relative;
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;
}

.brand-filter .size-filter {
    margin-left: 5%;
}

.brand-filter h3,
.size-filter h3,
.price-filter h3 {
    font-size: 16px;
    margin-bottom: 10px;
}

.size-options {
    display: flex;
    flex-wrap: wrap;
}

.size-button {
    margin: 5px;
    padding: 3px 8px;
    background-color: #e0e0e0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.size-button.active {
    background-color: #ffcccb;
    /* Change to your desired active color */
}

.filter-buttons {
    display: flex;
    justify-content: space-between;
    /* margin-top: 10px; */
    padding: 5%;
}

.apply-button,
.clear-button {
    padding: 8px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.clear-button {
    background-color: #dc3545;
}

.close-button {
    position: absolute;
    right: 0px;
    /* a bit of spacing from the right edge */

    /* vertically center the button with respect to its height */
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #555;
}

.close-button:hover {
    color: #333;
}

.brand-filter select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.size-options {
    display: flex;
    flex-wrap: wrap;
    gap: 0px;
    margin-top: 1px;
}