.myList-list {
    display: grid;
    grid-gap: 20px;
    padding-top: 5px;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 100px;
}

@media (min-width: 768px) {
    .myList-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1024px) {
    .myList-list {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 1280px) {
    .myList-list {
        grid-template-columns: repeat(4, 1fr);
    }
}

.myList-image {
    width: 100%;
    max-width: 400px;
    height: auto;
    margin-bottom: 20px;
    height: 200px;
}

.myList-page-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.myList-infiniteScrollContainer {
    padding-left: 5%;
    padding-right: 5%;
    margin-top: -10px;

    padding-bottom: 100px;
}

/* MyList.css */

/* Add the following styles to create the loading spinner overlay */
.loading-spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    /* Set a high z-index to make sure it appears on top of other elements */
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    /* Semi-transparent white border */
    border-top: 4px solid #ffffff;
    /* White color for the spinner's top border */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    /* Create a spinning animation */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}