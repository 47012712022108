/* Account.css */

.account-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.account-container {
    width: 100%;
}

.account-table {
    width: 100%;
    border-collapse: collapse;
}

.account-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}