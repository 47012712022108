.infiniteScrollContainer {
    padding-left: 5%;
    padding-right: 5%;
    margin-top: -15px;
}

.product-list {
    display: grid;
    grid-gap: 20px;
    padding-top: 5px;
    grid-template-columns: repeat(2, 1fr);
}

@media (min-width: 768px) {
    .product-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1024px) {
    .product-list {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 1280px) {
    .product-list {
        grid-template-columns: repeat(4, 1fr);
    }
}

.product-item {
    display: flex;
    flex-direction: column;
    /* align-items: center;
    text-align: center; */
}

.product-image-wrapper {
    position: relative;
}

.product-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin-bottom: 10px;
}

.overlay-text {
    position: absolute;
    top: 10px;
    left: 10px;
    /* background-color: rgba(0, 0, 0, 0.5); */
    color: black;
    padding: 5px;
    font-size: 12px;
    background-color: lightgrey;
    border-radius: 0.8rem;
    opacity: 0.7;
}

.product-details {
    width: 100%;
    margin-top: -10px;
    text-align: left;
    /* Add margin to align with images */
}

.overlay {
    position: absolute;
    top: 10px;
    right: 10px;
    /* background-color: rgba(0, 0, 0, 0.5); */
    padding: 2px;
    color: black;
    background-color: lightgrey;
    border-radius: 0.3rem;
    opacity: 0.5;
}

.overlay-icon {
    color: grey
}

.heart-icon {
    color: white;
    position: absolute;
    bottom: 18px;
    right: 5px;
    color: gray
}

.product-details h3 {
    margin-bottom: 5px;
}

.product-details p {
    margin-top: 5px;
}

.heart-icon-wrapper {
    /* display: inline-flex; */
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 8px;
    /* Increase this value to adjust the clickable area */
}

/* Add a new CSS class to style the red heart icon */
.heart-icon.red {
    color: red;
}


.product-sizes {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}

.product-size {
    padding: 3px 8px;
    /* Adjust padding to make the tags smaller */
    margin-right: 5px;
    margin-bottom: 5px;
    background-color: #f2f2f2;
    /* Light grey background color */
    border-radius: 5px;
    font-size: 12px;
    /* Adjust font size to make the tags smaller */
}

.product-details {
    /* Your styles for product details */
}

.product-pricing {
    /* Your styles for the pricing section */
}

.product-price {
    /* Styling for the original price when there is no discount */
    display: block;
}

.product-discount .original-price {
    /* Styling for the original price when there is a discount */
    color: #999;
    text-decoration: line-through;
    margin-right: 10px;
}

.product-discount .discounted-price {
    /* Styling for the discounted price */
    color: #d00;
    font-weight: bold;
}

.sold-out {
    background-color: lightgrey;
    color: orangered;
    padding: 4px;
    border-radius: 4px;
    margin-left: 8px;
}

.in-stock {
    /* Styling for the in stock message */
    color: #080;
}

.discount-tag {
    /* Styling for the discount tag */
    top: 0;
    right: 0;
    background-color: rgba(221, 122, 0, 0.878);
    color: #fff;
    padding: 3px;
    border-radius: 5px;
    font-weight: bold;
}