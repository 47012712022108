/* ImageComponent.css */

.no-fake-image-container {
    align-items: center;
    height: 100vh;
    /* Adjust the height as needed */
}

.my-image {
    max-width: 100%;
    /* Ensure the image fits 
    /* Ensure the image fits within its container */
}