.admin-spu-list-container {
    padding: 20px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding-bottom: 300px;
}

.admin-spu-list-container ul {
    list-style-type: none;
    padding: 0;
}

.admin-product-list {
    display: flex;
    flex-direction: column;
}

.admin-product-item {
    border-bottom: 1px solid red;
    /* or any color you prefer */
    margin-bottom: 16px;
    /* Adjust as per your design preference */
    padding-bottom: 16px;
    /* Adjust as per your design preference */

}
.admin-product-status {
    padding-bottom: 10px;
}

.admin-spu-list-header {
    padding-bottom: 30px;
}

.admin-spu-product-list {
    margin-top: 10px;
}